exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-media-allnews-jsx": () => import("./../../../src/pages/media/allnews.jsx" /* webpackChunkName: "component---src-pages-media-allnews-jsx" */),
  "component---src-pages-media-featuredpublications-jsx": () => import("./../../../src/pages/media/featuredpublications.jsx" /* webpackChunkName: "component---src-pages-media-featuredpublications-jsx" */),
  "component---src-pages-media-highlights-jsx": () => import("./../../../src/pages/media/highlights.jsx" /* webpackChunkName: "component---src-pages-media-highlights-jsx" */),
  "component---src-pages-media-jsx": () => import("./../../../src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */),
  "component---src-pages-media-mediacoverage-jsx": () => import("./../../../src/pages/media/mediacoverage.jsx" /* webpackChunkName: "component---src-pages-media-mediacoverage-jsx" */),
  "component---src-pages-media-onlinevideos-jsx": () => import("./../../../src/pages/media/onlinevideos.jsx" /* webpackChunkName: "component---src-pages-media-onlinevideos-jsx" */),
  "component---src-pages-media-studionews-jsx": () => import("./../../../src/pages/media/studionews.jsx" /* webpackChunkName: "component---src-pages-media-studionews-jsx" */),
  "component---src-pages-media-writings-jsx": () => import("./../../../src/pages/media/writings.jsx" /* webpackChunkName: "component---src-pages-media-writings-jsx" */),
  "component---src-pages-works-allprojects-jsx": () => import("./../../../src/pages/works/allprojects.jsx" /* webpackChunkName: "component---src-pages-works-allprojects-jsx" */),
  "component---src-pages-works-architecture-jsx": () => import("./../../../src/pages/works/architecture.jsx" /* webpackChunkName: "component---src-pages-works-architecture-jsx" */),
  "component---src-pages-works-exhibition-jsx": () => import("./../../../src/pages/works/exhibition.jsx" /* webpackChunkName: "component---src-pages-works-exhibition-jsx" */),
  "component---src-pages-works-interiordesign-jsx": () => import("./../../../src/pages/works/interiordesign.jsx" /* webpackChunkName: "component---src-pages-works-interiordesign-jsx" */),
  "component---src-pages-works-jsx": () => import("./../../../src/pages/works.jsx" /* webpackChunkName: "component---src-pages-works-jsx" */),
  "component---src-pages-works-productdesign-jsx": () => import("./../../../src/pages/works/productdesign.jsx" /* webpackChunkName: "component---src-pages-works-productdesign-jsx" */),
  "component---src-pages-works-progressiveresearch-jsx": () => import("./../../../src/pages/works/progressiveresearch.jsx" /* webpackChunkName: "component---src-pages-works-progressiveresearch-jsx" */),
  "component---src-pages-works-publication-jsx": () => import("./../../../src/pages/works/publication.jsx" /* webpackChunkName: "component---src-pages-works-publication-jsx" */),
  "component---src-pages-works-urbandesign-jsx": () => import("./../../../src/pages/works/urbandesign.jsx" /* webpackChunkName: "component---src-pages-works-urbandesign-jsx" */),
  "component---src-templates-media-jsx": () => import("./../../../src/templates/Media.jsx" /* webpackChunkName: "component---src-templates-media-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/Project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-theme-jsx": () => import("./../../../src/templates/Theme.jsx" /* webpackChunkName: "component---src-templates-theme-jsx" */)
}

